//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseCmsBlock from "../../node_modules/sitis-nuxt/lib/cms/CmsBlockMixin";
import ArticleCard from "~/components/blog/ArticleCard.vue";

export default BaseCmsBlock.extend({
  name: "LastArticlesBlock",
  data() {
    return {
      articles: [],
    };
  },
  async created() {
    const res = await this.$api.agent.transport
      .get(`articles?expand=category&per-page=${this.cfgs.quantity}`)
      .catch((err) => {
      });
    this.articles = res?.data || [];
  },
  components: {
    ArticleCard,
  },

});
